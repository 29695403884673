@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

* {
  font-family: 'Plus Jakarta Sans', 'Open Sans';
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}

:root {
  --black: #000000;
  --gold: #FFCD00;
  --white: #FFFFFF;
}

.loading-spinner {
  width: 150px;
  height: 150px;
  border: 25px solid var(--black);
  border-top-color: var(--gold);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.small-loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid var(--black);
  border-top-color: var(--gold);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#landing-img0 {
  transform: rotateY(20deg);
}

#landing-img1 {
  transform: rotateY(-20deg);
}

.tilt-box-wrap {
  transition: all 0.4s ease-out;
  perspective: 1000px
}

.tilt-box-wrap:hover {
  transition: all 0.3s linear;
}

.tilt-box {
  transition: all 1s ease-out;
  transform: rotateX(0deg) rotateY(0deg);
  perspective: 1000px;
  transform-style: preserve-3d;
}

.tilt-box strong {
  transform: translateZ(40px);
}

.tilter {
  width: 33.33333%;
  height: 33.33333%;
  position: absolute; 
  z-index: 1;
}

.tilter:nth-child(1) {
  left: 0;
  top: 0;
}

.tilter:nth-child(2) {
  left: 33.33333%;
  top: 0;
}

.tilter:nth-child(3) {
  left: 66.666%;
  top: 0;
}

.tilter:nth-child(4) {
  left: 0;
  top: 33.33333%;
}

.tilter:nth-child(5) {
  left: 33.33333%;
  top: 33.33333%;
}

.tilter:nth-child(6) {
  left: 66.666%;
  top: 33.33333%;
}

.tilter:nth-child(7) {
  left: 0;
  top: 66.666%;
}

.tilter:nth-child(8) {
  left: 33.33333%;
  top: 66.666%;
}

.tilter:nth-child(9) {
  left: 66.666%;
  top: 66.666%;
}

.tilter:nth-child(1):hover~.tilt-box {
  transform: rotateX(20deg) rotateY(-20deg) !important;
}

.tilter:nth-child(2):hover~.tilt-box {
  transform: rotateX(20deg) rotateY(0deg) !important;
}

.tilter:nth-child(3):hover~.tilt-box {
  transform: rotateX(20deg) rotateY(20deg) !important;
}

.tilter:nth-child(4):hover~.tilt-box {
  transform: rotateX(0deg) rotateY(-20deg) !important;
}

.tilter:nth-child(5):hover~.tilt-box {
  transform: rotateX(0deg) rotateY(0deg) !important;
}

.tilter:nth-child(6):hover~.tilt-box {
  transform: rotateX(0deg) rotateY(20deg) !important;
}

.tilter:nth-child(7):hover~.tilt-box {
  transform: rotateX(-20deg) rotateY(-20deg) !important;
}

.tilter:nth-child(8):hover~.tilt-box {
  transform: rotateX(-20deg) rotateY(0deg) !important;
}

.tilter:nth-child(9):hover~.tilt-box {
  transform: rotateX(-20deg) rotateY(20deg) !important; 
}

.blurred-container {
  background: rgba(21, 21, 21, 0.5);
  backdrop-filter: blur(10px);
  padding: 20px;
}

.darker-blurred-container {
  background: rgba(21, 21, 21, 0.665);
  backdrop-filter: blur(10px);
  padding: 20px;
}

.glow:hover {
  color: #fff;
  text-shadow: 1px 1px 10px #fff, 1px 1px 10px #ccc;
  text-align: center;
  transition: 0.25s;
}

.glowing-head {
  text-align: center;
  letter-spacing: -.04em;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#fff 30%, rgba(255, 255, 255, 0.201));
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 5rem;
  font-weight: 600;
  line-height: 1.1;
  transition: transform .96s cubic-bezier(.3, 1.17, .55, .99);
}

.glowing-head-primary {
  text-align: center;
  letter-spacing: -.04em;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(rgb(250 204 21) 30%, rgba(255, 255, 255, 0.201));
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 2rem;
  font-size: 5rem;
  font-weight: 600;
  line-height: 1.1;
  transition: transform .96s cubic-bezier(.3, 1.17, .55, .99);
} 

@media (max-width: 768px) {
  .glowing-head {
    font-size: 3rem;
  }

  .glowing-head-primary {
    font-size: 3rem;
  }
}

@media (max-width: 640px) {
  .glowing-head {
    font-size: 2rem;
  }

  .glowing-head-primary {
    font-size: 2rem;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.animated-outline {
  border-radius: 10px;
  margin: auto;

  display: grid;
  place-content: center;
  text-align: center;

  font-size: 1.5em;

  --border-size: 0.3rem;
  border: var(--border-size) solid transparent;

  /* Paint an image in the border */
  border-image: conic-gradient(from var(--angle),
      #ffffff 0deg 90deg,
      rgb(250 204 21) 90deg 180deg,
      rgba(255, 255, 255, 0.201) 180deg 270deg,
      rgb(250 204 21) 270deg 360deg) 1 stretch;
  background: rgb(255 255 255 / var(--opacity));
}

/* Animate when Houdini is available */
@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: "<number>";
    initial-value: 0.5;
    inherits: false;
  }

  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  .animated-outline {
    animation: rotate 4s linear infinite, opacityChange 3s infinite alternate;
  }
}

.animated-outline-counter {
  border-radius: 10px;
  margin: auto;

  display: grid;
  place-content: center;
  text-align: center;

  font-size: 1.5em;

  --border-size: 0.3rem;
  border: var(--border-size) solid transparent;

  /* Paint an image in the border */
  border-image: conic-gradient(from var(--angle),
      #ffffff 0deg 90deg,
      rgb(250 204 21) 90deg 180deg,
      rgba(255, 255, 255, 0.201) 180deg 270deg,
      rgb(250 204 21) 270deg 360deg) 1 stretch;
  background: rgb(255 255 255 / var(--opacity));
}

/* Animate when Houdini is available */
@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: "<number>";
    initial-value: 0.5;
    inherits: false;
  }

  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }

  @keyframes rotate-counter {
    to {
      --angle: -360deg;
    }
  }

  .animated-outline-counter {
    animation: rotate-counter 4.5s linear infinite, opacityChange 3s infinite alternate;
  }
}

.main-button-n {
  border-radius: 100rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1px;
  text-decoration: none;
  transition: all .4s;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 50px rgba(255, 217, 0, 0.566);
}

.main-button-n:hover {
  box-shadow: 0 0 50px rgb(250 204 21);
}

.main-button-inner {
  z-index: 1;
  background-color: #151515;
  border-radius: 100rem;
  position: relative;
}

.main-button-text {
  z-index: 2;
  grid-column-gap: 0.5em;
  white-space: nowrap;
  -webkit-text-fill-color: transparent;
  background-color: #151515;
  background-image: linear-gradient(to right, rgba(255, 255, 255, .7), #fff);
  -webkit-background-clip: text;
  background-clip: text;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.7rem;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;
  position: static;
  top: 1%;
  bottom: 1%;
  left: 1%;
  right: 1%;
  word-spacing: 3px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@keyframes slideRight {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.nav-menu path {
  stroke:rgb(161, 161, 170);
  fill: none;
  stroke-width: 6;
  stroke-linejoin: round;
  stroke-linecap: round;
  transition-duration: 0.35s;
  transition-timing-function: ease-in-out;
}

.nav-menu .menu-ends {
  stroke-dasharray: 70 1000;
}

.nav-menu .menu-middle {
  stroke-dasharray: 55 1000;
}

.menu-link {
  width: 100%;
  height: 10%;
  display: flex;
  justify-items: end;
  align-items: center;
  justify-content: center;
}

.menu-link-lines path {
  stroke:rgb(250, 204, 21);
  fill: none;
  stroke-width: 3;
  stroke-linejoin: round;
  stroke-linecap: round;
}

/* Ensure the table container has a consistent background color and allows horizontal scrolling */
.overflow-x-auto {
  background-color: rgb(14 24 30); /* Adjust to match your dark theme */
  overflow-x: auto;
}

.table-auto {
  min-width: 100%;
  border-collapse: collapse;
}

.table-auto th,
.table-auto td {
  border: 1px solid rgb(20, 30, 38); /* Adjust to match your dark theme */
  padding: 8px;
}

.table-auto th {
  background-color: rgb(10, 20, 26); /* Adjust to match your dark theme */
}

.table-auto tbody tr:hover {
  background-color: rgb(18, 28, 34); /* Adjust to match your dark theme */
}

/* Target Safari */
@media not all and (min-resolution:.001dpcm) {

  @media {
    .safari-course-item {
      max-height: 150px;
    }
  }

  @media screen and (min-width: 1200px){
    .safari-course-grid {
      margin-bottom: 150px;
    }
  }

  @media screen and (max-width: 1150px) {

    /* Safari-only CSS here */
    /* .safari-pagination {
      margin-top: 100px;
    } */

    .safari-course-grid {
      margin-bottom: 75px;
    }
  }
}